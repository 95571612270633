'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import { store } from '../../../app.init';
import esiosLogo from '../../../assets/images/eSIOS-logo.svg';
import * as translationEn from '../../../assets/translations/en.json';
import * as translationEs from '../../../assets/translations/es.json';
import servicesLogin from '../../../pages/login/login.service';
import { setCurrentLang, setPathname } from '../../../state/actions';
import { getSplittedRoute } from '../../../utils/url';

let templateHeader = (
  context,
  showResponsiveMenu,
  showOptions,
  showRapidAccess,
  pathname,
  logOut
) => {
  const transformToKey = (value) => {
    for (let key in translationEs.routes) {
      if (
        value === translationEs.routes[key] ||
        value === translationEn.routes[key]
      ) {
        return 'routes.' + key;
      }
    }
  };

  servicesLogin().tokenIsGood();
  const translateRoute = (location, lng) => {
    const listRoute = location.pathname.split('/').filter((i) => i);
    const translatedRoute = [];
    location.hash = '';

    listRoute.forEach((i) => {
      translatedRoute.push(
        i18n.t(transformToKey(i), {
          lng: lng,
        })
      );
    });
    if (
      (location.pathname.includes('operation-headlines') ||
        location.pathname.includes('titulares-de-operacion')) &&
      location.pathname.split('/')[3]?.length > 0
    ) {
      return (
        '/' +
        translatedRoute.toString().replaceAll(',', '/') +
        location.pathname.split('/')[3]
      );
    }

    if (
      location.pathname.includes('resultados') ||
      location.pathname.includes('results')
    ) {
      return (
        '/' +
        translatedRoute.toString().replaceAll(',', '/') +
        window.location.search
      );
    }
    if (location.pathname.includes('pvpc')) {
      return (
        '/' +
        translatedRoute.toString().replaceAll(',', '/') +
        window.location.search
      );
    }

    const id = getSplittedRoute().pop();
    if (
      (window.location.pathname.includes('analysis') ||
        window.location.pathname.includes('analisis')) &&
      window.location.pathname.at(-1) !== 's'
    ) {
      return '/' + translatedRoute.toString().replaceAll(',', '/') + id;
    } else {
      return '/' + translatedRoute.toString().replaceAll(',', '/');
    }
  };

  return html`
    <div class="esios-header">
      <div class="esios-header__top-header">
        <div class="esios-header__top-header__wrapper">
          <div class="items">
            <a
              class="item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.about')}"
              >${i18n.t('about')}</a
            >
            <div class="searcher">
              <span class="item">| </span>
              <a
                class="item"
                href="/${i18n.t('routes.lng')}/${i18n.t('routes.result')}"
                >${i18n.t('search')}</a
              >
              <form
                accept-charset="UTF-8"
                action="/${i18n.t('routes.lng')}/${i18n.t('header_results')}"
                method="get"
              >
                <input
                  class="search-input icon-search"
                  id="query"
                  name="query"
                  type="search"
                />
              </form>
            </div>

            <nav>
              <ul class="top-nav">
                <li class="button-container" id="opt1">
                  <button
                    class="button button-user"
                    @click=${(e) => {
                      showOptions(e);
                    }}
                  ></button>
                  <ul class="options">
                    <li>
                      <a
                        href="/${i18n.t('routes.lng')}/${i18n.t(
                          'routes.myesios'
                        )}"
                        >${i18n.t('my_esios')}</a
                      >
                    </li>
                    ${servicesLogin().validateSession() &&
                    html`<li>
                      <button
                        id="btnLogout"
                        @click=${(e) => {
                          logOut();
                        }}
                      >
                        Cerrar sesión
                      </button>
                    </li>`}
                  </ul>
                </li>

                <li class="button-container" id="opt2">
                  <button
                    class="button button-help"
                    @click=${(e) => {
                      showOptions(e);
                    }}
                  ></button>
                  <ul class="options">
                    <li>
                      <a
                        href="/${i18n.t('routes.lng')}/${i18n.t(
                          'routes.help-FAQ'
                        )}"
                        >${i18n.t('help-FAQ')}</a
                      >
                    </li>
                    <li>
                      <a
                        href="/${i18n.t('routes.lng')}/${i18n.t(
                          'routes.glossary'
                        )}"
                        >${i18n.t('glossary')}</a
                      >
                    </li>
                  </ul>
                </li>

                <li class="button-container" id="opt3">
                  <button
                    class="button ${i18n.t('lng-icon')}"
                    @click=${(e) => {
                      showOptions(e);
                    }}
                  ></button>
                  <ul class="options">
                    <li>
                      <a
                        href="${translateRoute(window.location, 'en')}"
                        class="lng-options-button"
                        @click=${(e) => {
                          e.preventDefault(), i18n.changeLanguage('en');
                          store.dispatch(setCurrentLang('en'));
                          store.dispatch(setPathname('/en'));
                          window.location.href = translateRoute(
                            window.location,
                            'en'
                          );
                        }}
                      >
                        ENGLISH
                      </a>
                    </li>
                    <li>
                      <a
                        href="${translateRoute(window.location, 'es')}"
                        class="lng-options-button"
                        @click=${(e) => {
                          e.preventDefault(), i18n.changeLanguage('es');
                          store.dispatch(setCurrentLang('es'));
                          store.dispatch(setPathname('/es'));
                          window.location.href = translateRoute(
                            window.location,
                            'es'
                          );
                        }}
                      >
                        ESPAÑOL
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div class="esios-header__main-header">
        <nav class="esios-header__main-header__wrapper">
          <a href="/${i18n.t('routes.lng')}" class="">
            <img src="${esiosLogo}" alt="Logo" id="esiosLogo" />
          </a>

          <ul class="main-items">
            <li class="item">
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t('routes.gyc')}"
                class="${pathname !== undefined &&
                pathname.split('/')[2] === i18n.t('routes.gyc')
                  ? 'selected'
                  : ''}"
                >${i18n.t('generation_and_consumption')}</a
              >
            </li>

            <li class="item">
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t('routes.myp')}"
                id="item2"
                class="${pathname !== undefined &&
                pathname.split('/')[2] === i18n.t('routes.myp')
                  ? 'selected'
                  : ''}"
                >${i18n.t('markets_and_prices')}</a
              >
            </li>

            <li class="item">
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t('routes.exchanges_i')}"
                class="${pathname !== undefined &&
                pathname.split('/')[2] === i18n.t('routes.exchanges_i')
                  ? 'selected'
                  : ''}"
                >${i18n.t('exchanges_i')}</a
              >
            </li>
          </ul>
        </nav>
        <button class="menu-button" @click=${() => showResponsiveMenu()}>
          <div class="bars">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </div>
        </button>
      </div>

      <div class="esios-header__responsive-menu">
        <div class="mod-menu-mobile">
          <div class="searcher">
            <form
              accept-charset="UTF-8"
              action="/${i18n.t('routes.lng')}/${i18n.t('header_results')}"
              method="get"
            >
              <input
                class="search-input icon-search"
                id="query"
                name="query"
                type="search"
              />
            </form>
          </div>
          <ul class="top-nav">
            <li class="button-container" id="optLang">
              <button
                class="button ${i18n.t('lng-icon')}"
                @click=${(e) => {
                  showOptions(e);
                }}
              ></button>
              <ul class="options">
                <li>
                  <button
                    @click=${() => {
                      window.location.pathname = translateRoute(
                        window.location,
                        'en'
                      );

                      i18n.changeLanguage('en');
                      store.dispatch(setCurrentLang('en'));
                      store.dispatch(setPathname('/en'));
                    }}
                  >
                    English
                  </button>
                </li>
                <li>
                  <button
                    @click=${() => {
                      window.location.pathname = translateRoute(
                        window.location,
                        'es'
                      );

                      i18n.changeLanguage('es');
                      store.dispatch(setCurrentLang('es'));
                      store.dispatch(setPathname('/es'));
                    }}
                  >
                    Español
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <ul class="menu-content">
          <li>
            <h3 class="title">${i18n.t('general')}</h3>

            <ul>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}">${i18n.t('home')}</a>
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.gyc')}"
                  >${i18n.t('generation_and_consumption')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.myp')}"
                  >${i18n.t('markets_and_prices')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.exchanges_i'
                  )}"
                  >${i18n.t('exchanges_i')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.gd')}"
                  >${i18n.t('demand_management')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.gyc'
                  )}/${i18n.t('routes.snp')}"
                  >${i18n.t('non_peninsular_systems')}</a
                >
              </li>
            </ul>
          </li>

          <li>
            <h3 class="title">${i18n.t('utils')}</h3>
            <ul>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.documentation'
                  )}"
                  >${i18n.t('documentation')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}"
                  >${i18n.t('downloads_files')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.proposals')}"
                  >${i18n.t('op_proposals')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.page'
                  )}/${i18n.t('routes.roadmap_slug')}"
                  >${i18n.t('roadmap_footer')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.page'
                  )}/${i18n.t('demand_renewable_forecasts_slug')}"
                  >${i18n.t('demand_renewable_forecasts')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.help-FAQ')}"
                  >${i18n.t('help-FAQ')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t('navigate_glossary')}"
                  >${i18n.t('glossary')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.sitemap')}"
                  >${i18n.t('sitemap_word')}</a
                >
              </li>
            </ul>
          </li>

          <li>
            <h3 class="title">${i18n.t('corporate')}</h3>
            <ul>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.about')}"
                  >${i18n.t('about')}</a
                >
              </li>
              <li class="link">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.contact')}"
                  >${i18n.t('contact')}</a
                >
              </li>
              <li class="link">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.other_links'
                  )}"
                  >${i18n.t('other_links')}</a
                >
              </li>
              <li class="link">
                <a href="https://www.ree.es/es/aviso-legal">
                  ${i18n.t('legal_advise')}
                </a>
              </li>

              <li class="link">
                <a href="https://www.ree.es/es/politica-de-privacidad">
                  ${i18n.t('privacy_policy')}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      ${context.rapidAccess
        ? html`<div class="esios-header__mod-rapid-access">
            <div class="esios-header__mod-rapid-access__wrapper">
              <h1
                id="buttonActivator"
                @click=${() => {
                  showRapidAccess();
                }}
              >
                ${i18n.t('rapid_access')}
              </h1>
              <nav
                class="esios-header__mod-rapid-access__wrapper__content expanded"
                id="rapid-access"
              ></nav>
            </div>
          </div> `
        : null}
    </div>
  `;
};

export { templateHeader };
