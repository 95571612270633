'use strict';

/**
 * @description Home Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import demandImageHigh_1 from '../../assets/images/demanda-real-high-1.jpg';
import demandImageHigh_2 from '../../assets/images/demanda-real-high-2.jpg';
import demandImageLow_1 from '../../assets/images/demanda-real-low-1.jpg';
import demandImageLow_2 from '../../assets/images/demanda-real-low-2.jpg';
import generacionHigh_1 from '../../assets/images/generacion-libreco2-high-1.jpg';
import generacionHigh_2 from '../../assets/images/generacion-libreco2-high-2.jpg';
import generacionLow_1 from '../../assets/images/generacion-libreco2-low-1.jpg';
import generacionLow_2 from '../../assets/images/generacion-libreco2-low-2.jpg';
import interconnectionImageHigh_1 from '../../assets/images/interconexiones-high-1.jpg';
import interconnectionImageHigh_2 from '../../assets/images/interconexiones-high-2.jpg';
import interconnectionImageLow_1 from '../../assets/images/interconexiones-low-1.jpg';
import interconnectionImageLow_2 from '../../assets/images/interconexiones-low-2.jpg';
import pvpcImageHigh_1 from '../../assets/images/pvpc-high-1.jpg';
import pvpcImageHigh_2 from '../../assets/images/pvpc-high-2.jpg';
import pvpcImageLow_1 from '../../assets/images/pvpc-low-1.jpg';
import pvpcImageLow_2 from '../../assets/images/pvpc-low-2.jpg';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { MAGNITUD_SYMBOLS } from '../../shared/enum/magnitud-symbols.js';
import { setHomeChangeOtherIndicatorsTime } from '../../state/actions.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { charts } from '../../utils/options-charts.js';
import { Utils } from '../../utils/utils.js';
import { servicesHome } from './home.services.js';
import { templateHome } from './home.template.js';
import statsImg from '../../assets/images/stats.svg';
import { getUrlParams } from '../../utils/url.js';
import { index } from 'd3';

let componentHome = (function async() {
  let context = {};
  let handlers = {
    onOtherIndicatorTimeChange: (event) => onOtherIndicatorTimeChange(event),
  };
  let otherIndicatorsData;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let renderSuccess = false;
  let dataTemp;
  let objToolbar = [];
  let hasDate = false;

  const selectTimeForIndicators = {
    id: 'select_otherIndicators',
    datetimeFor: 'otherIndicatorsTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: Utils.getArrayOfMinutes(5),
    changeTime: (value) => changeTimeOnIndicator(value),
  };
  const changeTimeOnIndicator = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    store.dispatch(setHomeChangeOtherIndicatorsTime(`${hours}:${minutes}`));
    renderOtherIndicatorsAgain(hours, minutes);
  };
  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = async () => {
    const state = store.getState();
    document.title = `${i18n.t('welcome')} | ${i18n.t('page_subtitle')}`;
    let tempDate;
    if (getUrlParams().date) {
      tempDate = getUrlParams().date.split('-');
      tempDate = getUrlParams().date
        ? `01-${tempDate[1]}-${tempDate[2]}` + 'T00:00'
        : `01-${dateSetTime().split('-')[1]}-${dateSetTime().split('-')[2]}`;
    }
    objToolbar = [
      {
        id: 1,
        slug: i18n.t('routes.gyc'),
        info: true,
        share: true,
        favorite: true,
        favId: 17,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/1293?compare_indicators=545,544&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 2,
        info: true,
        slug: i18n.t('routes.other-indicators'),
        share: true,
        favorite: true,
        favId: 25,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/541?compare_indicators=10034&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 3,
        slug: i18n.t('routes.myp'),
        info: true,
        share: true,
        favorite: true,
        favId: 18,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/600?compare_indicators=1001,705&start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 4,
        slug: i18n.t('routes.system-status'),
        info: true,
        share: true,
        favorite: true,
        favId: 26,
      },
      {
        id: 5,
        slug: i18n.t('routes.non-peninsular-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 19,
      },
      {
        id: 6,
        slug: i18n.t('routes.measured-generation'),
        info: true,
        share: true,
        favorite: true,
        favId: 20,
        analysis: true,
        url: (date) => {
          // Obtener la fecha seleccionada del datepicker
          const datepickerValue = document.getElementById('datepicker').value;
          const [day, month, year] = datepickerValue.split('/').map(Number);
          const selectedDate = new Date(year, month - 1, day);

          // Fecha límite (31/12/2023)
          const limitDate = new Date(2023, 11, 31); // Meses en JavaScript van de 0 a 11

          // Lista de indicadores
          let indicators = [
            '1169',
            '1168',
            '1165',
            '1167',
            '1160',
            '1159',
            '1157',
            '1158',
            '1150',
            '1151',
            '1154',
            '1155',
            '1153',
            '1163',
            '1170',
            '1171',
            '1161',
            '1162',
            '1166',
            '1152',
            '1156',
            '1164',
            '1756',
            '1757',
          ];

          // Eliminar el indicador 1152 si se cumple la condición de fecha
          if (selectedDate > limitDate) {
            indicators = indicators.filter((indicator) => indicator !== '1152');
          }

          return `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10195?compare_indicators=1169,1168,1165,1167,1160,1159,1157,1158,1150,1151,1154,1155,1153,1163,1170,1171,1161,1162,1166,1152,1156,1164,1756,1757&start_date=${date}T00:00&geoids=`;
        },
      },
      {
        id: 7,
        slug: i18n.t('routes.demand-evolution-embed'),
        info: true,
        share: true,
        favorite: true,
        favId: 21,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/1192?compare_indicators=1193&start_date=${
            tempDate ? tempDate : dateSetTime()
          }&geoids=`,
      },
    ];
    selectTimeForIndicators.currentHour = '00';
    selectTimeForIndicators.currentMinutes = '00';
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      objToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    objToolbar[id].widgetData = data;
    if (id == 0) {
      objToolbar[id].url = () => data.urlAnalysis;
    }
    objToolbar[id].datetime = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD_HH_mm')
      : moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH_mm');
    objToolbar[id].shareModal = html;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1));
  };

  let setUrl = (id, data) => {
    let date = moment(data).tz('Europe/Madrid').format('DD-MM-YYYY');
    let newUrl = objToolbar[id].url(date);
    objToolbar[id].url = () => newUrl;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1));
  };

  let renderComponent = async () => {
    const state = store.getState();
    let newDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    let urlDate;

    templateHome(context, handlers);

    let inputCalendar = document.querySelector('#datepicker');
    let divCalendar = document.querySelector('.is-day-selectable');
    inputCalendar.setAttribute('disabled', 'true');
    divCalendar.classList.add('disabled');

    let indicatorCaption = document.querySelector('#indicatorCaption');

    indicatorCaption.innerHTML =
      state.exampleReducer.currentLang === 'es'
        ? `
        LÍDERES EN 
        <span class="below-text">
          TRANSPARENCIA
        </span>`
        : `TRANSPARENCY 
        <span class="below-text">
          LEADERS
        </span>`;

    try {
      let date = document.getElementById('datepicker').value;
      if (urlParams.get('date')) {
        hasDate = true;
        date = urlParams.get('date');
        document.getElementById('datepicker').value = moment(
          urlDate,
          'DD-MM-YYYY'
        ).format('DD / MM / YYYY');
      }

      let dateForCalc = moment().tz('Europe/Madrid');
      let minutes = Math.floor(dateForCalc.format('mm') / 10) * 10;
      let sameDate = true;
      date == dateForCalc.format('DD / MM / YYYY') ||
      date == dateForCalc.format('DD/MM/YYYY') + '' ||
      date == dateForCalc.format('DD-MM-YYYY')
        ? (sameDate = true)
        : (sameDate = false);

      selectTimeForIndicators.currentHour = sameDate
        ? dateForCalc.format('HH')
        : '00';
      minutes = minutes <= 10 ? '00' : minutes - 10;

      selectTimeForIndicators.currentMinutes = `${sameDate ? minutes : '00'}`;

      let timeForCharts = moment(
        document.getElementById('datepicker').getAttribute('value'),
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      let shareData = window.location.search;
      servicesHome
        .getDataFromWidget('20', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date,
        })
        .then((data) => {
          spinnerNone('spinner20');
          if (data.indicators[0].values.length === 0) {
            document.querySelector('#char20 .chartLegend').style.display =
              'none';
            document
              .querySelector('#widget20 .esios-pie-graph')
              .classList.add('no-data-graphic');
          } else {
            document.querySelector('#char20 .chartLegend').style.display =
              'block';
            document
              .querySelector('#widget20 .esios-pie-graph')
              .classList.remove('no-data-graphic');
          }

          setInfoModal(
            5,
            data,
            templateShareModal('measured-generation', shareData),
            'all'
          );
          charts[20](data);
          let dateParse =
            data.indicators[0].values.length !== 0
              ? moment(data.indicators[0].values[0].x)
                  .tz('Europe/Madrid')
                  .format('MM / YYYY')
              : '-- / --';
          updateDateTime('measured-generation', dateParse);
          setUrl(5, data.indicators[0].values[0]?.x);
        })
        .finally(() => {
          loadedCheck(20);
        });

      servicesHome
        .getDataFromWidget('17', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date,
        })
        .then((data) => {
          spinnerNone('spinner17');
          data.indicators?.every(
            (indicator) => indicator.print_values.length == 0
          )
            ? Utils.noDataStatus('rect17', true)
            : Utils.noDataStatus('rect17', false);
          charts[17](data, timeForCharts);
          setInfoModal(
            0,
            data,
            templateShareModal('generation-and-consumption', shareData),
            'all'
          );
          Utils.showAndHideGraphLinesEyes(
            '#char17',
            '#char17 #widget17Data ul .icon-eye'
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget17 svg').remove();
            let eyes = document.querySelectorAll(
              '#char17 #widget17Data ul .icon-eye'
            );
            eyes.forEach((eye) => {
              eye.classList.remove('disabled');
            });
            charts[17](data, timeForCharts);
          });
        })
        .catch(() => {
          spinnerNone('spinner17');
          Utils.noDataStatus('rect17', true);
        })
        .finally(() => {
          loadedCheck(17);
        });

      servicesHome
        .getDataFromWidget('18', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date,
        })
        .then((data) => {
          spinnerNone('spinner18');

          data.indicators?.every(
            (indicator) => indicator.print_values.length == 0
          )
            ? Utils.noDataStatus('rect18', true)
            : Utils.noDataStatus('rect18', false);
          setInfoModal(
            2,
            data,
            templateShareModal('market-and-prices', shareData),
            'all'
          );
          charts[18](data, timeForCharts);
          Utils.showAndHideGraphLinesEyes(
            '#char18',
            '#char18 #widget18Data ul .icon-eye'
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget18 svg').remove();
            let eyes = document.querySelectorAll(
              '#char18 #widget18Data ul .icon-eye'
            );
            eyes.forEach((eye) => {
              eye.classList.remove('disabled');
            });
            charts[18](data, timeForCharts);
          });
        })
        .catch(() => {
          spinnerNone('spinner18');
          Utils.noDataStatus('rect18', true);
        })
        .finally(() => {
          loadedCheck(18);
        });

      servicesHome
        .getDataFromWidget('21', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date,
        })
        .then((data) => {
          let tempDate;
          if (data.indicators[0].print_values) {
            let size = data.indicators[0].print_values.length;
            let dateDemanda =
              data.indicators[0].print_values[size - 1].datetime;
            tempDate = moment(dateDemanda, 'YYYY-MM-DD').format(
              'DD-MM-YYYYT00:00'
            );
          } else {
            if (getUrlParams().date) {
              tempDate = getUrlParams().date.split('-');
              tempDate = getUrlParams().date
                ? `01-${tempDate[1]}-${tempDate[2]}` + 'T00:00'
                : `01-${dateSetTime().split('-')[1]}-${
                    dateSetTime().split('-')[2]
                  }`;
            }
          }
          spinnerNone('spinner21');
          setInfoModal(
            6,
            data,
            templateShareModal('demand-evolution-embed', shareData),
            'all'
          );
          charts[21](data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget21 svg').remove();
            charts[21](data);
          });
          objToolbar[6].url = () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/1192?compare_indicators=1193&start_date=${
              tempDate ? tempDate : dateSetTime()
            }&geoids=`;
          componentToolbar.render(objToolbar[6], '.esios-toolbar' + 7);
        })
        .finally(() => {
          loadedCheck(21);
        });

      servicesHome
        .getDataOtherIndicators('25', {
          lang: state.exampleReducer.currentLang,
          date,
          globalState: `${selectTimeForIndicators.currentHour}:${selectTimeForIndicators.currentMinutes}:00`,
        })
        .then((data) => {
          spinnerNone('spinner25');
          otherIndicatorsData = data;
          setInfoModal(
            1,
            data,
            templateShareModal('other-indicators', shareData),
            'all'
          );
          charts[25](data);
        })
        .finally(() => {
          loadedCheck(25);
        });
      servicesHome
        .getDataFromWidget('26', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          dateFormat: moment(new Date()).format('YYYY-MM-DDTHH:mm') + ':00',
        })
        .then((data) => {
          setInfoModal(
            3,
            data,
            templateShareModal('system-status', shareData),
            'all'
          );
          charts[26](data);

          let { indicators } = data;
          indicators = indicators.sort((a, b) => {
            if (a.result < b.result) {
              return -1;
            }
            if (a.result > b.result) {
              return 1;
            }
            return 0;
          });

          let name = indicators[0].short_name;
          let decimal = 1;
          if (indicators[0].id == 1001) {
            decimal = 2;
          }
          if (indicators[0].id == 1293) {
            decimal = 0;
          }

          let unit = indicators[0].magnitud[0].id;
          let magnitud = MAGNITUD_SYMBOLS[unit];
          let indicatorCaption = document.querySelector('#indicatorCaption');
          let indicatorId = indicators[0].id;
          let isHigh = false;
          let indicatorMaxValue = indicators[0].resultMax;
          let indicatorValue = indicators[0].result;
          indicatorValue === indicatorMaxValue
            ? (isHigh = true)
            : (isHigh = false);
          let indicatorDataList = [
            {
              id: 1001,
              url: indicatorValue % 2 === 0 ? pvpcImageLow_2 : pvpcImageLow_1,
              urlHigh:
                indicatorValue % 2 === 0 ? pvpcImageHigh_2 : pvpcImageHigh_1,
            },
            {
              id: 1293,
              url:
                indicatorValue % 2 === 0 ? demandImageLow_2 : demandImageLow_1,
              urlHigh:
                indicatorValue % 2 === 0
                  ? demandImageHigh_2
                  : demandImageHigh_1,
            },
            {
              id: 10026,
              url:
                indicatorValue % 2 === 0
                  ? interconnectionImageLow_2
                  : interconnectionImageLow_1,
              urlHigh:
                indicatorValue % 2 === 0
                  ? interconnectionImageHigh_1
                  : interconnectionImageHigh_2,
            },
            {
              id: 10033,
              url: indicatorValue % 2 === 0 ? generacionLow_2 : generacionLow_1,
              urlHigh:
                indicatorValue % 2 === 0 ? generacionHigh_1 : generacionHigh_2,
            },
          ];

          indicatorCaption.innerHTML = `
            ${name.toUpperCase()}
            <span class="below-text">
              ${
                indicators[0].values[0]
                  ? Utils.toNumber(indicators[0].values[0].y, decimal, 'comma')
                  : '-'
              }
              <span class="banner-unit">${magnitud}</span>
            </span>`;

          requestAnimationFrame(() => {
            const banner = document.getElementById('regular-banner').style;
            banner.transition = 'background-image .5s ease-in-out';
            indicatorDataList.map((el) => {
              if (indicatorId === el.id && isHigh) {
                banner.backgroundImage = `url(${el.urlHigh})`;
              } else if (indicatorId === el.id) {
                banner.backgroundImage = `url(${el.url})`;
              }
            });
          }, 0);
        })
        .finally(() => {
          loadedCheck(26);
        });

      servicesHome
        .getDataFromWidget('19', {
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: hasDate ? date : false,
        })
        .then((data) => {
          spinnerNone('spinner19');
          setInfoModal(
            4,
            data,
            templateShareModal('non-peninsular-demand', shareData),
            'all'
          );
          charts[19](data);
          let dateToDemand = getDateOfIndicator(data);
          updateDateTime('demand', dateToDemand);
        })
        .finally(() => {
          loadedCheck(19);
        });

      let getDateOfIndicator = (data) => {
        let dateToReturn = '';
        if (data) {
          if (
            data.indicators[0]?.print_values == undefined &&
            data.slug == i18n.t('routes.non-peninsular-demand')
          ) {
            return moment().format('DD / MM / YYYY');
          }
          dateToReturn = data.indicators[0]?.print_values[0]?.datetime;
          dateToReturn = dateToReturn?.split('T')[0];
          dateToReturn = moment(dateToReturn, 'YYYY-MM-DD').format(
            'DD / MM / YYYY'
          );
        }
        if (!dateToReturn) {
          dateToReturn = moment(date, 'DD/MM/YYYY').format('DD / MM / YYYY');
        }
        if (
          (data.slug == 'demanda-no-peninsular' ||
            data.slug == i18n.t('routes.non-peninsular-demand')) &&
          dateToReturn == 'Fecha inválida'
        ) {
          dateToReturn = moment().format('DD / MM / YYYY');
        }
        return dateToReturn;
      };

      document.getElementById('datepicker').value = moment(
        date,
        'DD-MM-YYYY'
      ).format('DD / MM / YYYY');

      $(document).on('change', '#datepicker', () => {
        hasDate = true;
        updateChars(false);

        let date = moment(
          document.getElementById('datepicker').value,
          'DD / MM / YYYY'
        )
          .tz('europe/Madrid')
          .format('DD-MM-YYYY');
        document
          .getElementById('snp-button-more')
          .setAttribute(
            'href',
            `/${i18n.t('routes.lng')}/${i18n.t('routes.gyc')}/${i18n.t(
              'routes.snp'
            )}?date=${date}`
          );
      });

      const updateChars = (currentDay = true) => {
        if (currentDay) {
          date = moment().tz('Europe/Madrid').format('DD/MM/YYYY');
        } else {
          date = $('#datepicker').val();
        }
        setParameter('date', moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY'));

        updateChar(17);
        updateChar(18);
        updateChar(19);
        updateChar(20);
        updateChar(21);
        updateChar(25, true);
        selectTimeForIndicators.currentHour = '00';
        selectTimeForIndicators.currentMinutes = '00';
        componentTimeSelector.render(
          selectTimeForIndicators,
          '#otherIndicatorsTimeSelector'
        );

        let dateParse2 = moment(date, 'DD/MM/YYYY').format('DD / MM / YYYY');
        updateDateTime('demand', dateParse2);
      };

      Utils.clickHoy('datepicker', updateChars, true);

      const updateDateTime = (id, date) => {
        document.getElementById(id).innerHTML = date;
      };

      const spinnerNone = (id) => {
        if (!!document.getElementById(id)) {
          document.getElementById(id).style.display = 'none';
        }
      };

      const spinnerTrue = (id) => {
        if (!!document.getElementById(id)) {
          document.getElementById(id).style.display = 'flex';
        }
      };

      let loaded = {
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        25: false,
      };

      const loadedCheck = (id) => {
        loaded[id] = true;
        let allLoaded = Object.values(loaded).some(
          (isLoaded) => isLoaded == false
        );

        if (!allLoaded) {
          let inputCalendar = document.querySelector('#datepicker');
          let divCalendar = document.querySelector('.is-day-selectable');
          inputCalendar.removeAttribute('disabled');
          divCalendar.classList.remove('disabled');
        }
      };

      const updateChar = async (idWidget, change = false) => {
        let shareData = window.location.search;
        loaded = {
          17: false,
          18: false,
          19: false,
          20: false,
          21: false,
          25: false,
        };

        let inputCalendar = document.querySelector('#datepicker');
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.setAttribute('disabled', 'true');
        divCalendar.classList.add('disabled');

        let svgs = document.querySelectorAll(`#widget${idWidget} svg`);
        let chartLegendHTML = document.querySelector(
          `#char${idWidget}  .chartLegend`
        );
        if (chartLegendHTML) {
          chartLegendHTML.style.display = 'none';
        }
        Utils.noDataStatus('rect' + idWidget, false);
        spinnerTrue('spinner' + idWidget);
        for (let svg of svgs) {
          svg.remove();
        }

        if (idWidget == '25') {
          servicesHome
            .getDataOtherIndicators(idWidget, {
              lang: state.exampleReducer.currentLang,
              globalState: '00:00:00',
              date,
              change,
            })
            .then((data) => {
              spinnerNone('spinner' + idWidget);
              charts[idWidget](data);
              setInfoModal(
                1,
                data,
                templateShareModal('other-indicators', shareData),
                'share'
              );
            })
            .finally(() => {
              loadedCheck(idWidget);
            });
        } else {
          servicesHome
            .getDataFromWidget(idWidget, {
              lang: state.exampleReducer.currentLang,
              globalState: state.exampleReducer,
              date,
            })
            .then((data) => {
              spinnerNone('spinner' + idWidget);
              if (idWidget === 17 || idWidget === 18 || idWidget === 21) {
                let svgs = document.querySelectorAll(`#widget${idWidget} svg`);
                for (let svg of svgs) {
                  svg.remove();
                }
                charts[idWidget](
                  data,
                  moment(date, 'DD / MM / YYYY').format('DD-MM-YYYY')
                );

                Utils.showAndHideGraphLinesEyes(
                  '#char17',
                  '#char17 #widget17Data ul .icon-eye'
                );

                window.addEventListener('resize', () => {
                  document.querySelector(`#widget${idWidget} svg`).remove();
                  let eyes = document.querySelectorAll(
                    `#char${idWidget} #widget${idWidget}Data ul .icon-eye`
                  );
                  eyes.forEach((eye) => {
                    eye.classList.remove('disabled');
                  });
                  charts[idWidget](
                    data,
                    moment(date, 'DD / MM / YYYY').format('DD-MM-YYYY')
                  );
                });
              } else {
                let svgs = document.querySelectorAll(`#widget${idWidget} svg`);
                for (let svg of svgs) {
                  svg.remove();
                }
                charts[idWidget](data);
              }
              if (idWidget === 17) {
                data.indicators?.every(
                  (indicator) => indicator.print_values.length == 0
                )
                  ? Utils.noDataStatus('rect17', true)
                  : Utils.noDataStatus('rect17', false);
                setInfoModal(
                  0,
                  data,
                  templateShareModal('generation-and-consumption', shareData),
                  'share'
                );
              } else if (idWidget === 18) {
                data.indicators?.every(
                  (indicator) => indicator.print_values.length == 0
                )
                  ? Utils.noDataStatus('rect18', true)
                  : Utils.noDataStatus('rect18', false);
                setInfoModal(
                  2,
                  data,
                  templateShareModal('market-and-prices', shareData),
                  'share'
                );
              } else if (idWidget === 19) {
                setInfoModal(
                  4,
                  data,
                  templateShareModal('non-peninsular-demand', shareData),
                  'share'
                );
              } else if (idWidget === 20) {
                setInfoModal(
                  5,
                  data,
                  templateShareModal('measured-generation', shareData),
                  'share'
                );
                let dateParse =
                  moment(data.indicators[0].values[0]?.x)
                    .tz('Europe/Madrid')
                    .format('MM / YYYY') || '-- / --';
                updateDateTime('measured-generation', dateParse);
              } else if (idWidget === 21) {
                setInfoModal(
                  6,
                  data,
                  templateShareModal('demand-evolution-embed', shareData),
                  'share'
                );
                let tempDate;
                if (data.indicators[0].print_values) {
                  let size = data.indicators[0].print_values.length;
                  let dateDemanda =
                    data.indicators[0].print_values[size - 1].datetime;
                  tempDate = moment(dateDemanda, 'YYYY-MM-DD').format(
                    'DD-MM-YYYYT00:00'
                  );
                } else {
                  if (getUrlParams().date) {
                    tempDate = getUrlParams().date.split('-');
                    tempDate = getUrlParams().date
                      ? `01-${tempDate[1]}-${tempDate[2]}` + 'T00:00'
                      : `01-${dateSetTime().split('-')[1]}-${
                          dateSetTime().split('-')[2]
                        }`;
                  }
                }

                objToolbar[6].url = () =>
                  `/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.analysis'
                  )}/1192?compare_indicators=1193&start_date=${
                    tempDate ? tempDate : dateSetTime()
                  }&geoids=`;
                componentToolbar.render(objToolbar[6], '.esios-toolbar' + 7);
              }
            })
            .finally(() => {
              loadedCheck(idWidget);
            });
        }
      };

      componentTimeSelector.render(
        selectTimeForIndicators,
        '#otherIndicatorsTimeSelector'
      );

      const RapidAccessData = [
        {
          id: '.homeGyCWidgetView',
          title: 'generation_and_consumption',
        },
        {
          id: '.homeOtherIndicators',
          title: 'other_indicators',
        },
        {
          id: '.homeMyPWidgetView',
          title: 'markets_and_prices',
        },
        {
          id: '.homeMetaforaWidgetView',
          title: 'system_status',
        },
        {
          id: '.esios-home__demanda',
          title: 'non-peninsular_demand',
        },
        {
          id: '.homeBalanceWidgetView',
          title: 'measured_generation',
        },
        {
          id: '.homeEYDWidgetView',
          title: 'evolution_of_demand',
        },
        {
          id: '.esios-layout__subcontent',
          title: 'current_news',
        },
      ];
      document.getElementById('esios-layout-banner').classList.add('banner-mb');
      componentRapidAccess.render(RapidAccessData);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;

    document.querySelector('.esios-layout').classList.add('esios-layout--home');
    document
      .querySelector('#newsModView .is-section-title')
      .classList.add('is-section-title--home');
    document
      .querySelector('#system-status-time')
      .classList.add('esios-toolbar-time__component--system-status');
    document
      .querySelector('.homeEYDWidgetView')
      .classList.add('homeEYDWidgetView--home');

    document
      .querySelector('#currentNews .esios-layout__subcontent__wrapper')
      .classList.add('esios-layout__subcontent__wrapper--home');
    //QuerySelector de "ACTUALIDAD"
    document
      .querySelector('#currentNews .is-title-with-line')
      .classList.add('is-title-with-line--home');
    //QuerySelector de "MAPAS"
    document
      .querySelector('#currentNews .mod-maps')
      .classList.add('mod-maps--home');

    document
      .querySelector('.esios-layout__breadcrumbs__wrapper')
      .classList.add('esios-layout__breadcrumbs__wrapper--home');

    document
      .querySelector('.esios-layout__wrap')
      .classList.add('esios-layout__wrap--home');
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  // ? FUNCIONES PARA VOLVER A RENDERIZAR EL CONTENIDO QUE CAMBIO SU ESTADO GLOBAL
  let renderOtherIndicatorsAgain = async (hour, minute) => {
    const state = store.getState();
    let inputCalendar = document.querySelector('#datepicker');
    const date = inputCalendar.value;
    let otherIndicatorsTime = state.exampleReducer.pages.home.indicatorsTime;
    selectTimeForIndicators.currentHour = hour;
    selectTimeForIndicators.currentMinutes = minute;
    try {
      otherIndicatorsData = await servicesHome.getDataOtherIndicators('25', {
        lang: state.exampleReducer.currentLang,
        date,
        globalState: hour + ':' + minute,
      });
      let shareData = window.location.search;

      setInfoModal(
        1,
        otherIndicatorsData,
        templateShareModal('other-indicators', shareData),
        'share'
      );
      renderOtherIndicators(otherIndicatorsData, otherIndicatorsTime);
      componentTimeSelector.render(
        selectTimeForIndicators,
        '#otherIndicatorsTimeSelector'
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  return {
    render: init,
  };
})();

export { componentHome };

const renderOtherIndicators = async (data, time) => {
  const indicators = data.indicators;
  const otherIndicatorsList = document.querySelector(
    '.esios-home__container-other .esios-home__widget-legend'
  );

  otherIndicatorsList.innerHTML = '';
  indicators.map((el) => {
    otherIndicatorsList.innerHTML += el.short_name
      ? `
    <div class="legend-item widget-summary-item widget-summary-item-${el.id}">
    <div class="item">
      <div class="label">
        ${el.short_name} (${
          el.values[0]
            ? moment(el.values[0].x).tz('Europe/Madrid').format('HH:mm')
            : '00:00'
        })
      </div>
    </div>
    <div class="value ${
      el.values[0] && el.values[0]?.y >= 0 ? 'true' : 'false'
    }">
    <span class="icon"> </span>
      <span class="is-number caption is-medium">
      ${
        el.values[0]
          ? el.values[0].y < 0
            ? Utils.toNumber(el.values[0].y * -1, 1, 'comma')
            : Utils.toNumber(el.values[0].y, 1, 'comma')
          : ''
      }<span class="unit">${MAGNITUD_SYMBOLS[el.magnitud[0].id]}</span>
      </span>
      <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
          el.id
        }" class="esios-toolbar__buttons toolbar-analysis">
            <img style="vertical-align: middle" src="${statsImg}">
            </a>
    </div>
  </div>`
      : `
    <div class="legend-item widget-summary-item widget-summary-item-${el.id}">
    <div class="item">
      <div class="label">
      ${el.short_name} (${
          el.values[0]
            ? moment(el.values[0].x).tz('Europe/Madrid').format('HH:mm')
            : '00:00'
        })
      </div>
    </div>
    <div class="value false">
    <span class="icon"> </span>
    <span class="is-number caption is-medium">
    ${
      el.values[0]
        ? el.values[0].y < 0
          ? Utils.toNumber(el.values[0].y * -1, 1, 'comma')
          : Utils.toNumber(el.values[0].y, 1, 'comma')
        : ''
    }<span class="unit">${MAGNITUD_SYMBOLS[el.magnitud[0].id]}</span>
    </span>
    <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${
          el.id
        }" class="esios-toolbar__buttons toolbar-analysis">
          <img style="vertical-align: middle" src="${statsImg}">
          </a>
  </div>
</div>`;
  });
};

const withComasOrDot = (val, separator) => {
  let newValue =
    val !== null
      ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
      : '-';
  return newValue.trim();
};
