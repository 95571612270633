'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';

let templateFooter = (context) => {
  return html`
    <div class="esios-footer">
      <div class="esios-footer__wrapper">
        <div class="esios-footer__menu">
          <div class="esios-footer__column">
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.gyc')}"
              >${i18n.t('generation_and_consumption')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.myp')}"
              >${i18n.t('markets_and_prices')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.gd')}"
              >${i18n.t('demand_management')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.exchanges_i')}"
              >${i18n.t('exchanges_i')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.gcsnp')}"
              >${i18n.t('non_peninsular_systems')}</a
            >
          </div>

          <div class="esios-footer__column">
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.documentation')}"
              >${i18n.t('documentation')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}"
              >${i18n.t('downloads_files')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.page')}/${i18n.t(
                'routes.proposals'
              )}"
              >${i18n.t('o_p_and_other_proposals')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.page')}/${i18n.t(
                'routes.roadmap_slug'
              )}"
              >${i18n.t('roadmap_footer')}</a
            >
            <!--    <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.page')}/${i18n.t(
              'routes.demand-renewable-forecasts'
            )}"
              >${i18n.t('demand_renewable_forecasts')}</a
            >-->
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.previsiones')}"
              >${i18n.t('demand_renewable_forecasts_OS')}</a
            >
          </div>

          <div class="esios-footer__column">
            <a class="esios-footer__item" href="/${i18n.t('routes.lng')}"
              >${i18n.t('start')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.help-FAQ')}"
              >${i18n.t('help-FAQ')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.contact')}"
              >${i18n.t('contact')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.glossary')}"
              >${i18n.t('glossary')}</a
            >
            <a
              class="esios-footer__item"
              href="/${i18n.t('routes.lng')}/${i18n.t('routes.other_links')}"
              >${i18n.t('other_links')}</a
            >
          </div>
        </div>

        <div class="esios-footer__social">
          <a href="https://www.facebook.com/RedElectricaREE" target="_blank">
            <svg
              height="44px"
              id="Untitled-P%E1gina%201"
              style="background-color:#ffffff00"
              version="1.1"
              viewBox="0 0 44 44"
              width="44px"
              x="0px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              y="0px"
            >
              <g>
                <g>
                  <path
                    d="M 0.5 22 C 0.5 9.8496 10.1685 0 22.0957 0 C 34.0229 0 43.6914 9.8496 43.6914 22 C 43.6914 34.1504 34.0229 44 22.0957 44 C 10.1685 44 0.5 34.1504 0.5 22 Z"
                    fill="#3a569c"
                  ></path>
                  <path
                    d="M 28.0817 22.3877 L 28.0891 19.6172 C 28.0891 18.1738 28.224 17.4014 30.2571 17.4014 L 32.9734 17.4014 L 32.9734 11.8638 L 28.6268 11.8638 C 23.406 11.8638 21.5703 14.5503 21.5703 19.0654 L 21.5703 22.3877 L 18.3119 22.3877 L 18.3119 27.9219 L 21.5703 27.9219 L 21.5703 44 L 28.0817 44 L 28.0817 27.9219 L 32.4282 27.9219 L 33.0039 22.3877 L 28.0817 22.3877 Z"
                    fill="#ffffff"
                  ></path>
                </g>
              </g>
            </svg>
          </a>

          <a href="https://twitter.com/RedElectricaREE" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="44px"
              height="44px"
              viewBox="0 0 256 256"
              xml:space="preserve"
            >
              <defs></defs>
              <g
                style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <polygon
                  points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 "
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                  transform="  matrix(1 0 0 1 0 0) "
                />
                <path
                  d="M 45 0 L 45 0 C 20.147 0 0 20.147 0 45 v 0 c 0 24.853 20.147 45 45 45 h 0 c 24.853 0 45 -20.147 45 -45 v 0 C 90 20.147 69.853 0 45 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
              </g>
            </svg>
          </a>

          <a href="https://es.linkedin.com/company/ree" target="_blank">
            <svg
              height="44px"
              id="Untitled-P%E1gina%201"
              style="background-color:#ffffff00"
              version="1.1"
              viewBox="0 0 44 44"
              width="44px"
              x="0px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              y="0px"
            >
              <g>
                <path
                  d="M 0.5 22 C 0.5 9.8496 10.1685 0 22.0957 0 C 34.0229 0 43.6914 9.8496 43.6914 22 C 43.6914 34.1504 34.0229 44 22.0957 44 C 10.1685 44 0.5 34.1504 0.5 22 Z"
                  fill="#006699"
                ></path>
                <g>
                  <path
                    d="M 16.6659 31.8453 L 11.8066 31.8453 L 11.8066 17.3176 L 16.6659 17.3176 L 16.6659 31.8453 Z"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M 14.236 15.334 L 14.236 15.334 L 14.2043 15.334 C 12.5736 15.334 11.5193 14.2185 11.5193 12.8247 C 11.5193 11.3986 12.6059 10.3142 14.2682 10.3142 C 15.9311 10.3142 16.9538 11.3986 16.9854 12.8247 C 16.9854 14.2185 15.9311 15.334 14.236 15.334 Z"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M 34.188 31.8453 L 29.3293 31.8453 L 29.3293 24.0733 C 29.3293 22.1204 28.6257 20.7884 26.8672 20.7884 C 25.5244 20.7884 24.7252 21.6864 24.3739 22.5544 C 24.2461 22.8648 24.2139 23.2988 24.2139 23.7328 L 24.2139 31.8453 L 19.3552 31.8453 C 19.3552 31.8453 19.4186 18.6808 19.3552 17.3176 L 24.2149 17.3176 L 24.2149 19.3747 C 24.8605 18.3854 26.0143 16.9771 28.5935 16.9771 C 31.7903 16.9771 34.188 19.0535 34.188 23.5158 L 34.188 31.8453 Z"
                    fill="#ffffff"
                  ></path>
                </g>
              </g>
            </svg>
          </a>

          <a
            href="https://www.youtube.com/user/RedElectricaREE"
            target="_blank"
          >
            <svg
              height="44px"
              id="Untitled-P%E1gina%201"
              style="background-color:#ffffff00"
              version="1.1"
              viewBox="0 0 44 44"
              width="44px"
              x="0px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              y="0px"
            >
              <g>
                <path
                  d="M 0.5 22 C 0.5 9.8496 10.1685 0 22.0957 0 C 34.0229 0 43.6914 9.8496 43.6914 22 C 43.6914 34.1504 34.0229 44 22.0957 44 C 10.1685 44 0.5 34.1504 0.5 22 Z"
                  fill="#de2a2a"
                  id="Elipse"
                ></path>
                <path
                  d="M 19 18.4448 L 26.0136 22.0017 L 19 25.6283 L 19 18.4448 ZM 10.7003 13.9581 C 9.2834 15.074 9 17.0562 9 22.0249 C 9 26.9937 9.4487 29.1386 10.5586 29.929 C 11.975 30.9377 14.8085 30.9984 21.9882 30.9984 C 29.1679 30.9984 32.2371 31.0914 33.5831 29.8593 C 34.7699 28.773 35 26.9937 35 22.0249 C 35 17.0562 34.6468 15.1689 33.5359 14.0976 C 32.3787 12.9817 29.2151 13.0049 22.0354 13.0049 C 14.8557 13.0049 12.0765 12.8742 10.7003 13.9581 Z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
          </a>
        </div>

        <div class="esios-footer__copyright">
          <nav class="esios-footer__nav">
            © Red Eléctrica de España /
            <a href="https://www.ree.es/es/aviso-legal"
              >${i18n.t('legal_advise')}</a
            >
            /
            <a href="https://www.ree.es/es/politica-de-privacidad"
              >${i18n.t('privacy_policy')}</a
            >
            /
            <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.sitemap')}"
              >${i18n.t('sitemap_word')}</a
            >
            /
            <a
              href="https://www.minetur.gob.es/energia/es-ES/SecretariaDeEstado/Paginas/SecretariaDeEstado.aspx"
              target="_blank"
              >Secretaría Estado Energía</a
            >
            / <a href="https://www.cnmc.es/" target="_blank">CNMC</a>
          </nav>

          <div class="esios-footer__logo-ree-text">
            ${i18n.t('ree_corporate')}
            <strong><a href="https://www.ree.es">www.ree.es</a></strong>
          </div>
        </div>
      </div>
    </div>
  `;
};

export { templateFooter };
