import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { store } from '../../../app.init';
import Modal from '../../../shared/components/modal/modal';
import Select from '../../../shared/components/selects/select';
import { setAnalysis } from '../../../state/actions';
import { sRender } from '../../../utils/render';
import {
  getUrlParams,
  setUrlParam,
  getSplittedRoute,
} from '../../../utils/url';

import {
  ANALYSIS_GROUP_ID,
  ANALYSIS_GROUP_ORDER_ID,
  ANALYSIS_TRUNCS,
} from './analysis-form.service';

const indlist = [
  '779',
  '780',
  '781',
  '782',
  '783',
  '784',
  '785',
  '786',
  '787',
  '788',
  '789',
  '790',
  '791',
  '792',
  '793',
  '794',
  '795',
  '796',
  '797',
  '798',
  '799',
  '800',
  '801',
  '802',
  '803',
  '804',
  '805',
  '806',
  '807',
  '808',
  '809',
  '810',
  '811',
  '812',
  '813',
  '814',
  '815',
  '816',
  '817',
  '857',
  '858',
  '859',
  '860',
  '861',
  '862',
  '863',
  '864',
  '865',
  '866',
  '867',
  '868',
  '869',
  '870',
  '871',
  '872',
  '873',
  '874',
  '875',
  '876',
  '877',
  '878',
  '879',
  '880',
  '881',
  '882',
  '883',
  '884',
  '885',
  '886',
  '887',
  '888',
  '889',
  '890',
  '891',
  '892',
  '893',
  '894',
  '895',
  '935',
  '936',
  '937',
  '938',
  '939',
  '940',
  '941',
  '942',
  '943',
  '944',
  '945',
  '946',
  '947',
  '948',
  '949',
  '950',
  '951',
  '952',
  '953',
  '954',
  '955',
  '956',
  '957',
  '958',
  '959',
  '960',
  '961',
  '962',
  '963',
  '964',
  '965',
  '966',
  '967',
  '968',
  '969',
  '970',
  '971',
  '972',
  '973',
  '1275',
  '1276',
  '1277',
  '1278',
  '1279',
  '1280',
  '1281',
  '1282',
  '1283',
  '1284',
  '1285',
  '1286',
  '1287',
  '1288',
  '1289',
  '1290',
  '1291',
  '1292',
  '1360',
  '1361',
  '1362',
  '1363',
  '1364',
  '1365',
  '1366',
  '1367',
  '1368',
  '1901',
  '1902',
  '1903',
  '1904',
  '1905',
  '1906',
  '1907',
  '1908',
  '1909',
  '10211',
  '10212',
  '10213',
  '10214',
  '10215',
  '10216',
  '10217',
  '10218',
  '10219',
  '10328',
  '10329',
  '10377',
  '10378',
  '10379',
  '10380',
  '10381',
  '10382',
  '10403',
  '10404',
  '10405',
];

const id = getSplittedRoute().pop();

const AnalysisFormGroupBy = ({ indicator }) => {
  let disabled = true;
  let values = [];
  let selected = {};

  if (indicator) {
    const res = getValuesAndSelected(indicator.tiempo, indicator);
    values = res.values;
    selected = res.selected;
    disabled = false;
  }
  return html`
    <div class="analysis-group-by esios-select">
      <div
        class="esios-analysis__widget-toolbar esios-analysis__widget-toolbar--flex"
      >
        <div class="toolbar-wrapper">
          <h3 class="analysis-group-by__title">${i18n.t('group')}</h3>
          <span class="widget-toolbar-item tooltip-switcher">
            <button
              class="widget-toolbar-info icon-widget-info"
              @click=${openModal}
            ></button>
            <div class="mod-tooltip">
              <p>${i18n.t('more_info')}</p>
            </div>
          </span>
        </div>
      </div>
      ${Select({ disabled, values, selected, onClick: handleGroupBy })}
      <div id="group-modal"></div>
    </div>
  `;
  function openModal() {
    sRender(
      Modal,
      'group-modal',
      i18n.t('group'),
      `<p style='text-transform: none;'>${i18n.t('group_desc')}</p>`
    );
  }
};

function handleGroupBy({ content }) {
  setUrlParam('groupby', content);
  store.dispatch(
    setAnalysis({
      moveBy: content,
    })
  );
  if (content == 'year') {
    let { start_date, end_date } = getUrlParams();
    let endDate = moment(end_date, 'DD-MM-YYYY')
      .endOf('year')
      .format('DD-MM-YYYY');
    let stDate = moment(
      '01-01-' + moment(start_date, 'DD-MM-YYYY').year(),
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');
    setUrlParam('start_date', stDate + 'T00:00');
    setUrlParam(
      'compare_start_date',
      moment(stDate, 'DD-MM-YYYY').subtract('1', 'year').format('DD-MM-YYYY') +
        'T00:00'
    );
    setUrlParam('end_date', endDate + 'T23:55');
  }

  if (content == 'month') {
    let { start_date } = getUrlParams();
    let stDate = moment(start_date, 'DD-MM-YYYY')
      .startOf('month')
      .subtract('1', 'month')
      .format('DD-MM-YYYY');
    setUrlParam('compare_start_date', stDate + 'T00:00');
    store.dispatch(setAnalysis({ compare_start_date: stDate + 'T00:00' }));
  }
  store.dispatch(setAnalysis({ groupby: content }));
  window.location.reload();
}

function getValuesAndSelected([time], indicator) {
  const gowithouttime = [684];
  // Months is not in the old project. See _getTruncs function.
  let disabled = {
    [ANALYSIS_GROUP_ID['minutes5']]: [false, true, true, false, false, false],
    [ANALYSIS_GROUP_ID['minutes10']]: [true, false, true, false, false, false],
    [ANALYSIS_GROUP_ID['minutes15']]: [
      true,
      true,
      false,
      false,
      false,
      false,
      false,
    ],
    [ANALYSIS_GROUP_ID['hour']]: [true, true, true, false, false, false, false],
    [ANALYSIS_GROUP_ID['day']]: [true, true, true, true, false, false],
    [ANALYSIS_GROUP_ID['month']]: [true, true, true, true, true, false],
    [ANALYSIS_GROUP_ID['year']]: [true, true, true, true, true, true],
  };

  let timeIdVal;
  if (time.id === 223) {
    timeIdVal = 4;
  } else if (time.id === 154) {
    timeIdVal = 225;
  } else if (time.id === 150) {
    timeIdVal = 3;
  } else {
    timeIdVal = time.id;
  }
  const disabledForCurrentId = indlist.includes(id);

  if (gowithouttime.includes(indicator.id)) {
    let row = disabled[timeIdVal];
    // Encontrar la posición del primer false
    let firstFalseIndex = row.indexOf(false);
    if (firstFalseIndex !== -1 && firstFalseIndex + 1 <= row.length) {
      for (let i = firstFalseIndex + 1; i < row.length; i++) {
        row[i] = true;
      }
    }
    disabled[indicator.id] = row;
  }

  const values = [
    {
      label: i18n.t('minutes5'),
      content: 'minutes5',
      disabled: disabledForCurrentId || disabled[timeIdVal][0],
    },
    {
      label: i18n.t('minutes10'),
      content: 'minutes10',
      disabled: disabledForCurrentId || disabled[timeIdVal][1],
    },
    {
      label: i18n.t('minutes15'),
      content: 'minutes15',
      disabled: disabledForCurrentId || disabled[timeIdVal][2],
    },
    {
      label: i18n.t('hours'),
      content: 'hour',
      disabled: disabledForCurrentId || disabled[timeIdVal][3],
    },
    {
      label: i18n.t('days'),
      content: 'day',
      disabled: disabledForCurrentId || disabled[timeIdVal][4],
    },
    {
      label: i18n.t('months'),
      content: 'month',
      disabled: disabledForCurrentId || disabled[timeIdVal][5],
    },
    {
      label: i18n.t('years'),
      content: 'year',
      disabled: disabledForCurrentId || disabled[timeIdVal][6],
    },
  ];
  const index = ANALYSIS_GROUP_ORDER_ID.indexOf(timeIdVal);
  const { groupby } = getUrlParams();
  let grouptime = groupby;
  if (gowithouttime.includes(indicator.id)) {
    grouptime = ANALYSIS_TRUNCS[timeIdVal];
  }
  let selected = values.find((i) => i.content === grouptime);
  if (!grouptime) {
    selected = values[index];
  }

  return {
    values,
    selected,
  };
}

export default AnalysisFormGroupBy;
