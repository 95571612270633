'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let BreadCrumbs = (context = {}, data = {}, slug = '', key = '') => {
  const breadCrumbsList = [
    {
      url: ['/es', '/en'],
      step: '',
    },
    {
      url: '/dashboard',
      step: 'dashboard',
    },
    {
      url: ['/es/pagina/hojas-de-ruta', '/en/page/roadmap'],
      step: 'market_network_codes_title',
    },
    {
      url: ['/es/acerca-de-esios', '/en/about-esios'],
      step: 'about',
    },
    {
      url: ['/es/contacto', '/en/contact'],
      step: 'contact',
    },
    {
      url: ['/es/analisis-movil', '/en/analisis-movil'],
      step: '',
    },
    {
      url: ['/es/mapa-del-sitio', '/en/sitemap'],
      step: 'sitemap_word',
    },
    {
      url: ['/es/unidades-fisicas', '/en/generation-units'],
      step: 'physical_units',
    },
    {
      url: ['/es/unidades-de-programacion', '/en/programming-units'],
      step: 'programming_units',
    },
    {
      url: ['/es/documentacion', '/en/documentation'],
      step: 'documentation',
    },
    {
      url: [`/es/documentacion/${slug}`, `/en/documentation/${slug}`],
      step: [
        {
          name: 'documentation',
          url: ['/es/documentacion', '/en/documentation'],
        },
        {
          name: `${key}`,
          url: '',
        },
      ],
    },
    {
      url: ['/es/glosario', '/en/glossary'],
      step: 'glossary',
    },
    {
      url: ['/es/ayuda', '/en/help'],
      step: 'help',
    },
    {
      url: ['/es/ayuda-preguntas-frecuentes', '/en/help-faq'],
      step: 'help-FAQ',
    },
    {
      url: ['/es/informacion', '/en/information'],
      step: 'information',
    },
    {
      url: ['/es/generacion-y-consumo', '/en/generation-and-consumption'],
      step: 'generation_and_consumption',
    },
    {
      url: [
        '/es/generacion-y-consumo/sistemas-no-peninsulares',
        '/en/generation-and-consumption/non-peninsular-systems',
      ],
      step: [
        {
          name: 'generation_and_consumption',
          url: ['/es/generacion-y-consumo', '/en/generation-and-consumption'],
        },
        {
          name: 'non_peninsular_systems',
          url: '',
        },
      ],
    },
    {
      url: ['/es/mapas-de-interes', '/en/interesting-maps'],
      step: 'maps',
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-eolicas',
        '/en/interesting-maps/wind-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'wind-installations-maps',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-cogen-residuos-biomasa-municipio',
        '/en/interesting-maps/cogeneration-wastes-biomass-installations-town-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'cogeneration-wastes-biomass-installations-town-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-evolucion-instalaciones-produccion-renovable',
        '/en/interesting-maps/renewable-installations-production-time-lapse-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'renewable-installations-production-time-lapse-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-termosolares',
        '/en/interesting-maps/solar-thermal-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'solar-thermal-installations-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-produccion',
        '/en/interesting-maps/production-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'production-installations-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-termosolares-municipio',
        '/en/interesting-maps/solar-thermal-installations-town-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'solar-thermal-installations-town-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-hidraulicas-municipio',
        '/en/interesting-maps/hydraulic-installations-town-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'hydraulic-installations-town-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-hidraulicas',
        '/en/interesting-maps/hydraulic-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'hydraulic-installations-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-cogeneracion-residuos-biomasa-biogas',
        '/en/interesting-maps/cogeneration-wastes-biomass-biogas-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'cogeneration-wastes-biomass-biogas-installations-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-fotovoltaicas',
        '/en/interesting-maps/solar-pv-installations-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'solar-pv-installations-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-fotovoltaicas-municipio',
        '/en/interesting-maps/solar-pv-installations-town-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'solar-pv-installations-town-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-instalaciones-eolicas-municipio',
        '/en/interesting-maps/wind-installations-town-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'wind-installations-town-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-de-consumidores-domesticos-con-contador-inteligente-telegestionado-por-comunidad-autonoma',
        '/en/interesting-maps/domestic-consumers-with-smart-metering-by-autonomous-community-map',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'domestic-consumers-with-smart-metering-by-autonomous-community-map',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/mapas-de-interes/mapa-de-produccion-anual-media-fotovoltaica',
        '/en/interesting-maps/mapa-de-produccion-anual-media-fotovoltaica',
      ],
      step: [
        {
          name: 'maps',
          url: ['/es/mapas-de-interes', '/en/interesting-maps'],
        },
        {
          name: 'map-of-annual-average-photovoltaic-energy-production',
          url: '',
        },
      ],
    },
    {
      url: [
        '/es/pagina/previsiones-demanda-renovable',
        '/en/page/demand-renewable-forecasts',
      ],
      step: 'demand_ren_g_f',
    },
    {
      url: ['/es/preguntas-frecuentes', '/en/faq'],
      step: 'faq',
    },
    {
      url: ['/es/gestion-de-la-demanda', '/en/demand-management'],
      step: 'demand_management',
    },
    {
      url: ['/es/pvpc', '/en/pvpc'],
      step: 'pvpc',
    },
    {
      url: [
        '/es/pagina/interrumpibilidad_snp',
        '/en/page/interruptibility_snp',
      ],
      step: 'interruptibility_title',
    },
    {
      url: ['/es/balance', '/en/balance'],
      step: 'balance',
    },
    {
      url: ['/es/intercambios-internacionales', '/en/international-exhanges'],
      step: 'exchanges_i',
    },
    {
      url: ['/es/curvas-de-ofertas', '/en/supply-curves'],
      step: 'supply_curves',
    },
    {
      url: [`/es/analisis`, `/en/analysis`],
      step: 'analysis',
    },
    {
      url: [`/es/analisis/${slug}`, `/en/analysis/${slug}`],
      step: 'analysis',
    },
    {
      url: ['/es/sujetos-del-mercado', '/en/balance-responsible-parties'],
      step: 'market_subjects',
    },

    {
      url: [
        '/es/participantes-habilitados-en-subastas-de-capacidad',
        '/en/entitled-participants',
      ],
      step: 'participants_auction',
    },
    {
      url: ['/es/otros-enlaces', '/en/other-links'],
      step: 'other_links',
    },
    {
      url: [
        '/es/pagina/propuestas-de-procedimientos-de-operacion',
        '/en/page/operation-procedures-proposals',
      ],
      step: 'reg_chang_prop',
    },
    {
      url: ['/es/mercados-y-precios', '/en/market-and-prices'],
      step: 'myp',
    },
    {
      url: ['/es/previsiones-os', '/en/forecasts-os'],
      step: 'demand_renewable_forecasts_OS',
    },
    {
      url: ['/es/descargas', '/en/downloads'],
      step: 'downloads',
    },
    {
      url: ['/es/calendario-de-subastas', '/en/auctions-calendar'],
      step: 'auctions_calendar',
    },
    {
      url: ['/es/web-esios-informa', '/en/web-esios-communications'],
      step: 'umm',
    },
    {
      url: ['/es/titulares-de-operacion', '/en/operation-headlines'],
      step: 'news',
    },
    {
      url: [
        `/es/titulares-de-operacion/${slug}`,
        `/en/operation-headlines/${slug}`,
      ],
      step: [
        {
          name: 'news',
          url: ['/es/titulares-de-operacion', '/en/operation-headlines'],
        },
        {
          name: `${key}`,
          url: '',
        },
      ],
    },
    {
      url: ['/es/mi-esios', '/en/my-esios'],
      step: 'myesios',
    },
    {
      url: ['/es/resultados', '/en/results'],
      step: 'search',
    },
    {
      url: ['/es/web-calculadora', '/en/web-calculator'],
      step: 'lumios',
    },
    {
      url: [
        '/es/pagina/codigos-red-operacion',
        '/en/page/information-about-implementatio-of-operation-network-codes',
      ],
      step: 'informacion sobre implementacion de los codigos de red de operacion',
    },
    {
      url: ['page/information-about-implementatio-of-operation-network-codes'],
      step: 'information about implementation of operation network codes',
    },
    {
      url: [
        '/es/pagina/codigos-red-conexion',
        '/en/page/connection-network-codes',
      ],
      step: 'information_about_implementation_of_operation_network_codes',
    },
    {
      url: ['/es/activaciones-del-servicio', '/en/service-activations'],
      step: 'service_activation',
    },
    {
      url: [
        '/es/pagina/informacion-sobre-implementacion-de-los-codigos-de-red-de-mercados',
        '/en/page/information-about-implementation-of-market-network-codes',
      ],
      step: 'market_network_codes_title',
    },
    {
      url: ['/es/saldo-frontera', '/en/border-balance'],
      step: 'saldo-frontera',
    },
  ];
  let completePath = [];

  const getPath = () => {
    const pathName = window.location.pathname;
    const lang = pathName.split('/')[1];
    let item = breadCrumbsList.filter(
      (element) => element.url[0] === pathName || element.url[1] === pathName
    );
    let page;
    let url;
    let enUrl;
    let secondPage;

    if (!item[0]) {
      return '';
    } else if (typeof item[0].step == 'object') {
      page = item[0].step[0].name;
      url = item[0].step[0].url[0];
      enUrl = item[0].step[0].url[1];
      secondPage = item[0].step[1].name;
      lang == 'es'
        ? completePath.push(page, url, secondPage)
        : completePath.push(page, enUrl, secondPage);

      return i18n.t(page), url, enUrl, i18n.t(secondPage);
    } else {
      return i18n.t(item[0].step).toUpperCase();
    }
  };

  // * NEW WAY OF POSITIONATE THE BREADCRUMBS
  window.addEventListener('scroll', function (ev) {
    var getBreadcrumb = document.querySelector(
      '.esios-layout__breadcrumbs__wrapper'
    );
    var main = document.querySelector('.esios-layout__wrap');
    if (main) {
      var distanceToTop = main.getBoundingClientRect().top;

      distanceToTop <= 0
        ? getBreadcrumb.classList.add('esios-breadcrumbs-nav__wrapper')
        : getBreadcrumb.classList.add('esios-layout__breadcrumbs__wrapper');
      distanceToTop > 0
        ? getBreadcrumb.classList.remove('esios-breadcrumbs-nav__wrapper')
        : getBreadcrumb.classList.add('esios-breadcrumbs__wrapper');

      let calendar = $('.esios-layout__breadcrumbs__calendar-container');
      let topModDate = calendar.offset() && calendar.offset().top + 40;
      if (
        calendar.length > 0 &&
        distanceToTop <= 0 &&
        window.innerWidth > 992
      ) {
        $('#ui-datepicker-div').css({
          position: 'fixed',
          top: '60px',
        });
      } else if (
        calendar.length > 0 &&
        window.innerWidth < 540 &&
        calendar.offset().top - $(window).scrollTop() < 0
      ) {
        $('#datepicker').datepicker('hide');
      } else {
        $('#ui-datepicker-div').css({
          position: 'absolute',
          top: topModDate + 'px',
        });
      }
    }
  });

  let col = data.select ? 8 : data.button ? 7 : 9;
  let colSelect = data.showSelect ? 1 : 4;

  let isAnalisis = window.location.pathname.includes(i18n.t('routes.analysis'));

  return html`
    <div
      class="esios-layout__breadcrumbs__wrapper ${isAnalisis
        ? 'is__analisis'
        : ''}"
    >
      <div class="wrap-breadcrumb t-max-height">
        <div class="row t-margin-0 t-max-height">
          <div
            class="esios-layout__breadcrumbs__container col-lg-${col} col-md-${col} col-sm-${col} col-xs-${col}"
          >
            <div class="esios-layout__breadcrumbs__wrap">
              <p>${i18n.t('you_are')}:</p>
              <a
                href="${i18n.t('routes.lng')}"
                class="esios-layout__breadcrumbs__home"
                >${i18n.t('home').toUpperCase()}</a
              >
              ${getPath() === ''
                ? ''
                : html`<span
                    class="esios-layout__breadcrumbs__separator"
                  ></span>`}
              <span class="esios-layout__breadcrumbs__page">
                ${completePath.length == 0
                  ? getPath().toUpperCase()
                  : html`<a
                      class="esios-layout__breadcrumbs__home"
                      href="${completePath[1]}"
                      >${i18n.t(completePath[0]).toUpperCase()}</a
                    >`}
              </span>
              ${completePath.length !== 0
                ? html` <span
                      class="esios-layout__breadcrumbs__separator"
                    ></span>
                    <span class="esios-layout__breadcrumbs__page"
                      >${i18n.t(completePath[2]).toUpperCase()}</span
                    >`
                : ''}
            </div>
          </div>
          ${data.button
            ? html` <div
                class="col-lg-2 col-md-2 col-sm-2 col-xs-2 esios-layout__breadcrumbs__button"
              >
                <button id="buttonHeader"></button>
              </div>`
            : ''}
          ${data.select
            ? html` <div
                class="col-lg-${colSelect} ${window.location.pathname.includes(
                  'calendario-de-subastas'
                ) || window.location.pathname.includes('auctions-calendar')
                  ? 'col-sm-4 col-xs-4'
                  : 'col-sm-1 col-xs-1'} esios-layout__breadcrumbs__select esios-period-selector"
              >
                <div id="selectYear">
                  ${data.showSelect
                    ? html`<select id="selectUnit">
                        <option
                          class="option"
                          value=""
                          selected
                          disabled
                          hidden
                        >
                          kWh
                        </option>
                      </select>`
                    : ''}
                </div>
              </div>`
            : ''}
          ${context.calendar
            ? html`<div
                class="esios-layout__breadcrumbs__calendar-container col-lg-3 col-md-3 col-sm-3 col-xs-3"
              >
                ${context.calendarActionButtons
                  ? html`<div
                      class="esios-layout__breadcrumbs__calendar__change-datetime prev-btn"
                    ></div>`
                  : null}

                <div class="mod-date mod-date-days is-day-selectable">
                  <input
                    id="datepicker"
                    name="datepicker"
                    readonly="readonly"
                    value=${context.date}
                  />
                  <span></span>
                </div>

                ${context.calendarActionButtons
                  ? html`<div
                      class="esios-layout__breadcrumbs__calendar__change-datetime next-btn"
                    ></div>`
                  : null}
              </div>`
            : ''}
        </div>
      </div>
    </div>
  `;
};
export { BreadCrumbs };
